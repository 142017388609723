import i18n from 'roddeh-i18n';
import i18nModules, { locals } from './locale'
import { isBrowser } from '../env';
import countries from 'i18n-iso-countries';

const toRFC5646 = ({ lang, product }) => product ? `${lang}-x-${product}` : lang;

i18n.availableRegionLangs = [];
i18n.supportedRegionLangs = {};

i18n.resolve = (key, { plural, ...variables } = {}, ctx = {}, defaultValue = key) => {
  let locale = i18n.current;
  if (i18n.current in i18n.supportedRegionLangs && i18n.supportedRegionLangs[i18n.current] === undefined) { // might still be loading i18n.current translations from remote (locale not available locally in this repo) and hence be suported but undefined
    console.warn("Language", i18n.current, "seems to be loading yet, using 'en' as fallback");
    locale = 'en';
  }
  const lang = i18n.supportedRegionLangs[locale].translate();

  let txed = key;
  if (key) {
    txed = lang(key, plural, variables, { region: i18n.region, ...ctx });
    if (txed === key) {
        txed = lang(key, plural, variables, ctx);
    }
    if (txed === key) {
        txed = !(key.indexOf('.') >= 0 && key.at(-1) !== '.') ? 
                  defaultValue : 
                  i18n.resolve( key.split('.').slice(1).join('.'), variables, ctx, defaultValue);
    }
  }
  return txed;
}
i18n.resolveValue = (values) => {
  if (typeof values === 'string') {
    return i18n.resolve(values);
  }
  if (values?.[i18n.current] !== undefined) {
    return values[i18n.current];
  }
  const langWithoutRegion = i18n.current?.split('-')?.[0];
  if (values?.[langWithoutRegion] !== undefined) {
    return values[langWithoutRegion];
  }
  return values?.[Object.keys(values)[0]];
}

const selectLanguageFrom = (supportedLangs) => {
  if (supportedLangs) {
    const locales = isBrowser() ? [...navigator.languages.map(l => l.toLowerCase()), 'en'] : [i18n.current, 'en']; // eslint-disable-line no-undef
    const languages = locales.filter(Boolean)
                             .reduce((all, l) => [...all, l, l.split('-')[0]], []) // [..., 'en-US', ...] -> [..., 'en-US', 'en', ...]
                             .filter((l, i, a) => supportedLangs.includes(l) && a.indexOf(l) === i);
    
    i18n.current = languages[0];
    i18n.currentLocale = locales.find(l => l === i18n.current); // first locale for selected language;

    console.log('[I18N] selectLanguageFrom:', {selected: i18n.current, supportedLangs, locales});
  }
}

const languageDetector = () => {
  selectLanguageFrom(Object.keys(i18n.supportedRegionLangs));
}

const registerCountryLocale = () => {
  if (i18n.availableRegionLangs) {
    const getCountryLocale = (lang) => { try { return require(`i18n-iso-countries/langs/${lang}`); } catch { return require(`i18n-iso-countries/langs/en`); } };

    const countryLocale = getCountryLocale(i18n.current);
    
    countries.registerLocale(countryLocale);
    i18n.countries = {
      getNames: () => Object.values(countries.getNames(countryLocale.locale)),
      getCodes: () => Object.keys(countries.getNames(countryLocale.locale)),
      getName: (code) => countries.getName(code, countryLocale.locale),
      getCode: (name) => countries.getAlpha2Code(name, countryLocale.locale)
    };
  }
}

const loadMomentLocale = (loc) => {
  const txMoment = (t) => i18n.resolve(t, {}, { config: "moment" });

  loc   = i18n.region === 'us' ? 'en'    : loc;
  loc   = i18n.region === 'uk' ? 'en-gb' : loc;
  loc ??= i18n.currentLocale?.toLowerCase()
  loc ??= i18n.current
  
  try { require(`moment/locale/${loc}`); } catch { try { require(`moment/locale/${loc.replace(/-.*/, '')}`); } catch { console.warn(`Module moment/locale/${loc} not found`) } }
  try {
    require('moment').updateLocale(loc, {
      relativeTime: {
        s: txMoment('%d seconds'),
        m: txMoment('1 minute'),
        mm: txMoment('%d minutes'),
        h: txMoment('1 hour'),
        hh: txMoment('%d hours')
      }
    });
  } catch(_) {
    console.warn(`Module moment/locale not found`)
  }
}


i18n.update = _ => i18n.capabilities.forEach(capability => capability());
i18n.use = (capability) => {
  i18n.capabilities ??= [];
  i18n.capabilities.push(capability);
  return i18n;
};

i18n.refreshLocale = async (lang = i18n.current, product = i18n.product) => {  
  i18n.current = lang && i18n.supportedRegionLangs[lang] ? lang : "en";
  i18n.product = product;

  const normalizedLang = toRFC5646({ lang: i18n.current, product: i18n.product });
  const translations = await i18nModules[normalizedLang];
  i18n.supportedRegionLangs[i18n.current] = { translate: _ => i18n.create(translations) };
  i18n.translator = i18n.supportedRegionLangs[i18n.current].translate().translator;
  
  i18n.update();
  
  console.log("I18N refreshLocale:", i18n.current, i18n.product, i18n.availableRegionLangs, i18n.supportedRegionLangs)
  return i18n.resolve;
}

i18n.loadProduct = async (product) => {
  i18n.product = product;
  await i18n.refreshLocale(); // i18n module loads config from local storage ... but we should likely not do that and ask explicitly to use the actual product somehow
}

i18n.init = () => {
  i18n.availableRegionLangs = Object.keys(locals).map(l => l.replace(/-x-.*$/, '')).filter((l,i,a) => a.indexOf(l) === i);
  i18n.current = (isBrowser() ? [...navigator/*eslint-disable-line  no-undef*/.languages, 'en'] : ['en']).find(l => i18n.availableRegionLangs.includes(l) || i18n.availableRegionLangs.includes(l.split('-')[0]));
  if (!i18n.availableRegionLangs.includes(i18n.current)) i18n.current = i18n.current.split('-')[0];
  i18n.supportedRegionLangs = Object.keys(locals).reduce((all, lang) => ({
    ...all,
    [lang]: { translate: _ => i18n.create(locals[lang]()) }
  }), {});
  
  console.log("I18N init:", i18n.current, i18n.product, i18n.availableRegionLangs, i18n.supportedRegionLangs)

  i18n.translator = i18n.supportedRegionLangs[i18n.current].translate().translator;
  i18n.update();

  console.log("I18N init:", i18n.current, i18n.product, i18n.availableRegionLangs, i18n.supportedRegionLangs)
};


i18n.use(languageDetector)
    .use(loadMomentLocale)
    .use(registerCountryLocale)
    .init();


require('../firebase/config').addOnConfigurationChanged(config => {
  i18n.region  = config.region;
  i18n.product = config.localisation?.product;
  i18n.availableRegionLangs = config.localisation?.supportedLangs.map(({lang}) => lang) || ['en'];
  selectLanguageFrom(i18n.availableRegionLangs);
  i18n.supportedRegionLangs = Object.keys(locals).filter(l => i18n.availableRegionLangs.includes(l.split('-')[0])).reduce((all, lang) => ({
    ...all,
    [lang]: { translate: _ => i18n.create(locals[lang]()) }
  }), i18n.current in locals ? {} : {[i18n.current]: undefined});

  console.log("I18N addOnConfigurationChanged:", i18n.current, i18n.product , i18n.availableRegionLangs, i18n.supportedRegionLangs)

  i18n.refreshLocale(i18n.current, i18n.product);
});

export default i18n;
