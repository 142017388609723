import { Joi } from '../../../validation/rules';

const Study = () => require('.').Study; // To avoid cyclic dependency

const SEVERITIES_GROUPS = {
  NORMAL: ['NORMAL', 'NORMAL/MILD'],
  MILD: ['NORMAL/MILD', 'MILD', 'MILD/MODERATE'],
  MODERATE: ['MILD/MODERATE', 'MODERATE', 'MODERATE/SEVERE'],
  SEVERE: ['MODERATE/SEVERE', 'SEVERE'],
};

const SEVERITY_TO_GROUPS = Object.keys(SEVERITIES_GROUPS).reduce((acc, severityGroup) => {
  const severities = SEVERITIES_GROUPS[severityGroup];
  severities.forEach(severity => {
    acc[severity] ??= [];
    acc[severity].push(severityGroup);
  });
  return acc;
}, {});

const Report = {
  MESSAGES: {
    "AC/WARNING/301": {
      subject: "Study under 4h",
      message: "Diagnosis based on 2-4h valid data."
    },
    "AC/WARNING/302": {
      subject: "Study too long",
      message: "Diagnosis based on the first 13-14 hours of valid data."
    },
    "AC/WARNING/304": {
      subject: "Sensor fell off",
      message: "The patient reported the sensor fell off during the night. There was not enough data to produce a diagnosis, so this sleep test must be repeated."
    },
    "AC/WARNING/305": {
      subject: "Sensor fell off",
      message: "The patient reported the sensor fell off during the night. There was sufficient data to produce a test report, but this should be used for reference only and the sleep test should be repeated."
    },
    "AC/WARNING/306": {
      subject: "Sensor fell off",
      message: "The patient reported the sensor fell off during the night. There was not enough data to produce a diagnosis, so we recommend that the sleep test is repeated."
    },
     "AC/WARNING/307": {
      subject: "Sensor fell off",
      message: "The patient reported the sensor fell off during the night. There was sufficient data to produce a test report, but this should be used for reference only and we recommend that the sleep test is repeated."
    },
    "AC/WARNING/ODI/005": {
      subject: "This index has been obtained from the neck sensor",
      message: "This index has been obtained from the neck sensor because of low confidence in the finger sensor data due to the number of borderline events.",
    },
    "AC/WARNING/ODI/006": {
      subject: "This index has been obtained from the neck sensor",
      message: "This index has been obtained from the neck sensor due to not enough data collected from the finger oximeter",
    },
    /*"AC/WARNING/308": {
      subject: "CPAP noise detected",
      message: "This sleep test contains undiagnosable sections due to external noise, maybe due to having used a CPAP"
    },*/
    "AC/INFO/ODI/000": {
      subject: "This index has been obtained from the oximeter.",
      text: "This index has been obtained from the oximeter."
    },
    "AC/INFO/ODI/001": {
      subject: "Low confidence in borderline 3%-4% event count.",
      text: "Low confidence in borderline 3%-4% event count."
    },
    "AC/INFO/AHI/000": {
      subject: "This index has been obtained via automated diagnosis.",
      text: "This index has been obtained via automated diagnosis.",
    },
    "AC/WARNING/VALID-ACU-REPEAT-03": {
      subject: 'module.diagnosis.fe.view.study.repeat_reason_sumamry.ACU-REPEAT-03',
      text: 'module.diagnosis.fe.view.study.repeat_reason_warn.VALID-ACU-REPEAT-03'
    },
    "AC/WARNING/VALID-ACU-REPEAT-01": {
      subject: 'module.diagnosis.fe.view.study.repeat_reason_sumamry.ACU-REPEAT-01',
      text: 'module.diagnosis.fe.view.study.repeat_reason_warn.VALID-ACU-REPEAT-01'
    },
    "AC/WARNING/ACU-REPEAT-01": {
      subject: 'module.diagnosis.fe.view.study.repeat_reason_sumamry.ACU-REPEAT-01',
      text: 'module.diagnosis.fe.view.study.repeat_reason_warn.ACU-REPEAT-01'
    }
  },
  EVENT_TYPES: ['OBSTRUCTIVE-APNOEA', 'OBSTRUCTIVE-APNOEA-100%', 'OBSTRUCTIVE-APNOEA-90%', 'HYPOPNOEA', 'HYPOPNOEA-7090%', 'HYPOPNOEA-70%', 'CENTRAL-OBSTRUCTIVE-APNOEA', 'CENTRAL-APNOEA', 'UNCLASSIFIED-APNOEA', 'UNDEFINED-APNOEA'], // Use to order events as defined by commercial team
  SEVERITIES : ['UNKNOWN', 'INCONCLUSIVE', 'NORMAL', 'NORMAL/MILD', 'MILD', 'MILD/MODERATE', 'MODERATE', 'MODERATE/SEVERE', 'SEVERE'],
  SEVERITIES_GROUPS,
  SEVERITY_TO_GROUPS,
  get schema() { return Joi.object().keys({
    file                 : Joi.string(),  // TODO should be a relative URI ?
    signalsFile          : Joi.string(),  // TODO should be a relative URI ?
    signalsScreenshotFile: Joi.string(),  // TODO should be a relative URI ?
    code       : Joi.string().regex(/^AC\/[0-9][0-9][0-9]$/),
    messages   : Joi.array().items(Joi.object().keys({
      code: Joi.string().regex(/AC\/(INFO|WARNING|ERROR)\/[0-9][0-9][0-9]/),
      text: Joi.string()
    })),
    diagnosis  : Joi.object()
    .pattern(/^ACU-AHI[34]$/, Joi.object().keys({
      events: Joi.object().pattern(Joi.string(), Joi.object().keys({
        group: Joi.string(),
        average: Joi.number().precision(1),
        percentage: Joi.number().precision(1)
      })),
      severity: Joi.string().valid(...Report.SEVERITIES),
      value   : Joi.number().integer().positive().allow(0),
      events_count: Joi.number().integer().positive().allow(0),
      max_events_length: Joi.object().keys({
        value: Joi.number().positive().allow(0),
        unit: Joi.string()
      }),
      min_events_length: Joi.object().keys({
        value: Joi.number().positive().allow(0),
        unit: Joi.string()
      }),
      average_events_length: Joi.object().keys({
        value: Joi.number().positive().allow(0),
        unit: Joi.string()
      })
    }))
    .pattern(/^ACU-ODI[34]$/, Joi.object().keys({
      messages : Joi.array().items(Joi.object().keys({
        code: Joi.string().regex(/AC\/(INFO|WARNING|ERROR)\/.*/),
        text: Joi.string()
      })),
      severity: Joi.string().valid(...Report.SEVERITIES),
      value   : Joi.number().integer().positive().allow(0)
    })),
  }); },
  events: {
    SIGNALS_FILE_UPDATED            : {}, // TODO: to be deprecated???
    SIGNALS_SCREENSHOT_FILE_UPDATED : {},
    ANALYSIS_REPORT_UPDATED         : {
      snapshot: (ev, prev) => {
        const testPayload = Object.entries(ev.data.tests || {[prev.data.sequence || 0]: ev.data}).reduce((ts, [seq, testEvent]) => {
          const prevData = (prev.data.tests?.[seq] || prev.data);

          //unsafe: only override what the Report schema define. 
          const {unsetOldFields} = require('../../../snapshot');
          const signals = unsetOldFields(prevData?.recording?.signals, testEvent.recording?.signals);// Mark undefined event fields in recording.signals to override all signals information with new event data
          const report  = unsetOldFields(prevData?.report, testEvent.report); // Mark undefined event fields in report to override all report information with new event data
          if (prevData?.report?.signalsFile) {
            report.signalsFile = prevData?.report?.signalsFile;
          }

          return {...ts, [seq]: { report, recording: {signals} }};
        } ,{});
  
        return { data: ev.aggregate.name === Study().name ? { tests: testPayload } : testPayload[prev.data.sequence || 0] };
      }
    }
  },
  commands: {
  },
  queries: {
    VIEW_DIAGNOSIS_SUMMARY: {
      // TODO: Implement read model
    },
    VIEW_DIAGNOSIS_REPORT: {
      // TODO: Implement read model
    },
    GET_ACUAHI3_VALUE: {
      // TODO: Implement read model
    },
    GET_ACUAHI3_SEVERITY: {
      // TODO: Implement read model
    },
    GET_ACUODI3_VALUE: {
      // TODO: Implement read model
    },
    GET_ACUODI3_SEVERITY: {
      // TODO: Implement read model
    },
    GET_ACUAHI4_VALUE: {
      // TODO: Implement read model
    },
    GET_ACUAHI4_SEVERITY: {
      // TODO: Implement read model
    },
    GET_ACUODI4_VALUE: {
      // TODO: Implement read model
    },
    GET_ACUODI4_SEVERITY: {
      // TODO: Implement read model
    },
    GET_SPO2_LT88_PARAMETER: {
      // TODO: Implement read model
    },
    GET_HYPOXIC_BURDEN: {
      // TODO: Implement read model
    },
    VIEW_RESPIRATORY_REPORT: {
      // TODO: Implement read model
    },
    VIEW_CARDIAC_REPORT: {
      // TODO: Implement read model
    },
    VIEW_SPO2_REPORT: {
      // TODO: Implement read model
    },
    VIEW_SNORING_REPORT: {
      // TODO: Implement read model
    },
    VIEW_EVENTS_CLASSIFICATION_REPORT: {
      // TODO: Implement read model
    },
    VIEW_DIAGNOSIS_POSITION_REPORT: {
      // TODO: Implement read model
    },
    VIEW_POSITION_PER_HOUR_REPORT: {
      // TODO: Implement read model
    }
  }
}

export { Report };